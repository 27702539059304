.product-details {
  list-style: none;
  margin: 0 auto 56px;
  text-align: center;

  @media (min-width: $break-point-tablet) {
    align-items: center;
    display: flex;
    justify-content: space-between;
    max-width: none;
    text-align: left;
    width: fit-content;
    margin: 0 auto 80px;

    .product-details__texts {
      padding-left: 80px;
      padding-right: 0;
      width: 540px;
    }

    &:nth-child(even) {
      flex-direction: row-reverse;

      .product-details__texts {
        padding-left: 0;
        padding-right: 80px;
      }
    }
  }

  .product-details__image {
    display: block;
    margin: 32px auto 0;
    max-width: 288px;

    @media (min-width: $break-point-tablet) {
      margin: 0;
      max-width: 450px;
    }

    @media (min-width: $break-point-desktop) {
      max-width: 510px;
    }
  }

  .product-details__texts {
    width: 100%;
    padding: 0;

    @media (min-width: $break-point-tablet) {
      padding: 0 0 0 30px;
    }
  }

  h3 {
    font-style: normal;
    line-height: 24px;
    margin: 16px auto 0 !important;
    max-width: 288px;
    padding: 0;
    text-align: center;

    @media (min-width: $break-point-tablet) {
      line-height: 32px;
      margin: 24px 0 0 !important;
      max-width: 460px;
      text-align: left;
    }
  }
}

.product-details--rebrand {
  h3 {
    font-weight: 400 !important;
  }
}
